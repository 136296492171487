import $ from "jquery";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'fitvids';

lazySizes.init();

$(document).ready(function() {

	var mypage = $(location).attr('pathname');
  var transform = $('.transformer');

	$('button.hamburger').on('click', function() {
		$(this).toggleClass('active');
		transform.toggleClass('is-open');
	});

	
	if( mypage === '/contact') {
		CMSMap.UI.refresh();
	}

// great background images

   function image_bg() {
	$('[data-bg]').each(function() {
		var bg = $(this).data('bg');

		$(this).css({
			'background-image': 'url(' + bg + ')',
			'background-size': 'cover',
			'background-position': '50% 50%'
		});
	});

	}

image_bg();

});

$(document).on('lazybeforesizes', function(e){
    //use width of parent node instead of the image width itself
    e.detail.width = $(e.target).closest(':not(picture)').innerWidth() || e.detail.width;
});

fitVids();

